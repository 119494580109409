// components
import PageLayout from 'src/components/PageLayout';
// sections
import Projects from './sections/Projects';
import Heading from './sections/Heading';
import { PageProps } from 'gatsby';
import { FC } from 'react';

const Portfolio: FC<PageProps> = () => {
  return (
    <PageLayout withStartProjectForm={false} withFooter={false}>
      <Heading />
      <Projects />
    </PageLayout>
  );
};

export default Portfolio;
